import { SiteConfig } from '../types';
import siteConfigJSON from './site.json';
import defaultConfigJSON from './default.json';

const config = {...siteConfigJSON} as SiteConfig;

// if (process.env.NODE_ENV === 'development') {
//     config = {
//         ...siteConfigJSON,
//         ...defaultConfigJSON
//     } 
// }

export const siteConfig: SiteConfig = config;
